/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import MailSvg from "../images/mail.svg"

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const data = useStaticQuery<Queries.LayoutQueryQuery>(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="text-white">
        <nav className="m:hidden h-[80px] px-[24px] bg-bg-dark flex items-center justify-between">
          <h1 className="text-[30px] leading-[40px] font-['Arial_Black']">
            <Link to="/">{data.site?.siteMetadata?.title}</Link>
          </h1>

          <a href="#contact">
            <button>
              <MailSvg className="inline-block mr-[8px] relative top-[-2px]" />
              お問い合わせ
            </button>
          </a>
        </nav>

        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout
